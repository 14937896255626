import React from "react";
// import frame2 from '../Assets/images/forget-img.jpg'
import frame2 from "../Assets/images/Resetpassword.jpg";
import { useState } from "react";
import Profile from "../Assets/images/profile.png";
import { HiMiniEye } from "react-icons/hi2";
import { RiEyeOffFill } from "react-icons/ri";
import { useForm } from "react-hook-form";
import { makeApiRequest } from "../services/commonAxiosService";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";

function Changepassword() {
  const [eyeopen, seteyeopen] = useState("text");
  const [eyesopen, seteyesopen] = useState("text");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const toggleone = () => {
    seteyeopen((prevType) => (prevType === "password" ? "text" : "password"));
  };
  const toggletwo = () => {
    seteyesopen((prevType) => (prevType === "password" ? "text" : "password"));
  };

  const onSubmit = async (data) => {
      try {
        if(data.confirmPassword !== data.newPassword){
            toast.error("New password and confirm password not match");
            return
        }
      data['emailToken'] = searchParams.get("token")
      console.log("data=====",data);
      // return
      let params = {
        url: "reset-password",
        method: "post",
        data,
      };
      let response = await makeApiRequest(params);
      console.log(response,"response")
      if (response.status) {
        toast.success(response.message);
        navigate('/login')
      }
    } catch (error) {
      console.log(error,"error")
      
      toast.error(error.response.data);
    }
  };
  return (
    <div className="App">
      <div className="container-fluid">
        <div className="custom-sign min-vh-100">
          <div className="row">
            <div className="col-lg-12">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <div className="custom-inside-sign">
                    <div className="text-center">
                      <img style={{ width: "50px" }} src={Profile}></img>
                      <p className="fw-bold">Change Password</p>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div class="mb-3 mt-3 position-relative">
                        <label
                          for="exampleInputEmail1"
                          class="form-label fw-bold"
                        >
                          New Password
                        </label>
                        <input
                          type={eyeopen}
                          class="form-control custom-input py-2"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="new password"
                          {...register("newPassword", {
                            required: true,
                            minLength: 8,
                          })}
                        />
                        {errors.newPassword &&
                          errors.newPassword.type === "required" && (
                            <span class="error-msg">
                              This field is required
                            </span>
                          )}
                        {errors.newPassword &&
                          errors.newPassword.type === "minLength" && (
                            <span class="error-msg">
                              This field required min 8 charcters
                            </span>
                          )}
                        {eyeopen === "password" ? (
                          <RiEyeOffFill
                            onClick={toggleone}
                            className="position-absolute min-eye-icon-01"
                          />
                        ) : (
                          <HiMiniEye
                            onClick={toggleone}
                            className="position-absolute min-eye-icon-01"
                          />
                        )}
                        {/* <RiEyeOffFill onClick={toggleone} className="position-absolute min-eye-icon-01" />
                                                <HiMiniEye onClick={toggleone} className="position-absolute min-eye-icon-01" /> */}
                      </div>
                      <div class="mb-3 mt-3 position-relative">
                        <label
                          for="exampleInputEmail1"
                          class="form-label fw-bold"
                        >
                          Confirm Password
                        </label>
                        <input
                          type={eyesopen}
                          class="form-control custom-input py-2"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="confirm password"
                          {...register("confirmPassword", {
                            required: true,
                            minLength: 8,
                          })}
                        />
                        {errors.confirmPassword &&
                          errors.confirmPassword.type === "required" && (
                            <span class="error-msg">
                              This field is required
                            </span>
                          )}
                        {errors.confirmPassword &&
                          errors.confirmPassword.type === "minLength" && (
                            <span class="error-msg">
                              This field required min 8 charcters
                            </span>
                          )}
                        {eyesopen === "password" ? (
                          <RiEyeOffFill
                            onClick={toggletwo}
                            className="position-absolute min-eye-icon-01"
                          />
                        ) : (
                          <HiMiniEye
                            onClick={toggletwo}
                            className="position-absolute min-eye-icon-01"
                          />
                        )}
                        {/* <RiEyeOffFill onClick={toggletwo} className="position-absolute min-eye-icon-01" />
                                                <HiMiniEye onClick={toggletwo} className="position-absolute min-eye-icon-01" /> */}
                      </div>
                      <div className="text-center w-100 mt-4 mb-3">
                        <button type="submit" class="custom-sign-btn w-100">
                          Changepassword
                        </button>
                      </div>
                    </form>
                    <div></div>
                  </div>
                </div>
                <div className="col-lg-7 animi">
                  <div className="custom-sign-right">
                    <img
                      src={frame2}
                      class="animated-image"
                      alt="Animated Image"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Changepassword;
