let config = {}
if(process.env.NODE_ENV == "development"){
    config = {
        backendurl : "https://api.banicoin.io/admin/"
    }
}else{
    config = {
        backendurl : "https://api.banicoin.io/admin/"
    }
}

export default config