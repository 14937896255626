import React, { useEffect, useState } from 'react';

import DataTable from 'react-data-table-component'
import { makeApiRequest } from '../services/commonAxiosService';
import { FaFileDownload } from "react-icons/fa";


function Listtask() {

    const [data, setTableData] = useState([]);
    useEffect(() => {
        const getTaskData = async () => {
            try {
                let params = {
                    url: "/list-task",
                    method: "get"
                }
                let response = await makeApiRequest(params);
                console.log("response", response);
                if (response.status) {
                    setTableData(response.data)
                }
            } catch (error) {
                console.log('getTaskData error', error)
            }
        }
        getTaskData()
    }, [])
    const formatDate = (dateString) => {
        const dateTime = new Date(dateString);

        const formattedDateTime = new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "UTC",
        }).format(dateTime);

        const [month, day, yearTime] = formattedDateTime.split('/');
        const [year, time] = yearTime.split(',');

        const formattedDateTimeWithComma = `${year}-${month}-${day}, ${time}`;

        return formattedDateTimeWithComma;
    };
    const columnsone = [
        {
            name: 'S.no',
            selector: row => row.sno,
            sortable: true,
            width: "100px"
        },
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,
            width: "150px",
        },
        {
            name: 'Description',
            selector: row => row.desc,
            sortable: true,
        },
        {
            name: 'Reward',
            selector: row => row.reward,
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
            cell: (row) => <div className="date">{formatDate(row.date)}</div>
        },
        {
            name: 'Task Img',
            selector: row => row.download,
            sortable: true,

        }
    ];

    const dataone = data &&
        data.map((row, index) => ({
            sno: index + 1,
            title: row.taskTitle,
            desc: row.taskDesc,
            reward: row.taskReward,
            type: row.taskType,
            status: row.status,
            date: row.createdAt,
            download:
                <div className="custom-download-icon">
                    <img src={row.taskImgUrl}/>
                </div>,
        }));

    return (
        <div className='App'>
            <div className='custom-list-task'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h2>List Task</h2>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='custom-inside-list-task'>
                                        <div className='liquidity-table-1 mt-3'>
                                            <DataTable columns={columnsone} data={dataone} theme="solarized" defaultSortAsc={true}
                                                pagination
                                                highlightOnHover
                                                dense />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Listtask