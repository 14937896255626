import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from './Seperate/Dashboard';
import "../src/Assets/css/Dashboard.css"
import Login from './Seperate/Login';
import '../src/Assets/css/Sign.css'
import Forgetpassword from './Seperate/Forgetpassword';
import Changepassword from './Seperate/Changepassword';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ProductedRoute from './services/ProductedRoutes';

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          {/* Redirect from root '/' to '/dashboard/add-task' */}
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgetpassword" element={<Forgetpassword />} />
          <Route exact path="/changepassword" element={<Changepassword />} />
          <Route element = {<ProductedRoute/>}>
          {/* <Route>  */}
            <Route path="/" element={<Navigate to="/dashboard/add-task" replace />} />
            <Route exact path="/dashboard/add-task" element={<Dashboard />} />
            <Route exact path="/dashboard/list-task" element={<Dashboard />} />
            <Route exact path="/dashboard/list-user" element={<Dashboard />} />
            <Route exact path="/dashboard/change-password" element={<Dashboard />} />
          </Route>
        </Routes>
      </BrowserRouter>
      {/* element = {<ProductedRoute/>} */}
    </div>
  );
}

export default App;