import React, { useState } from "react";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { makeApiRequest } from "../services/commonAxiosService";
import { toast } from "react-toastify";
import { FaFileDownload } from "react-icons/fa";
import rabbit from "../Assets/images/rabbit.png";
import { useNavigate } from "react-router-dom";

function Addtask() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const options = [
    { value: "Bani", label: "Bani" },
    { value: "Partners", label: "Partners" },
  ];

  const [selectedOption, setSelectedOption] = useState({
    value: "Bani",
    label: "Bani",
  });

  const handleChange = (option) => {
    setSelectedOption(option);
  };
  const onSubmit = async (data) => {
    try {
      const file = data.taskImage[0];
      if (!file) {
        toast.error("Please upload an image.");
        return;
      }

      // Validate file type
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        toast.error("Only PNG, JPEG, and JPG files are allowed.");
        return;
      }
      const formData = new FormData();
      formData.append("taskTitle", data.taskTitle);
      formData.append("taskDesc", data.taskDesc);
      formData.append("taskReward", data.taskReward);
      formData.append("taskType", selectedOption.value);
      formData.append("taskImage", file);

      let params = {
        url: "/add-task",
        method: "post",
        data: formData,
        header: "image",
      };
      let response = await makeApiRequest(params);
      if (response.status) {
        toast.success(response.message);
        navigate("/dashboard/list-task");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="App">
      <div className="custom-task">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>Add Task</h2>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-task-inside">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div class="mb-3 row">
                        <label for="taskTitle" class="col-sm-4 col-form-label">
                          Task Title
                        </label>
                        <div class="col-sm-8">
                          <input
                            type="text"
                            class="form-control input-form"
                            id="taskTitle"
                            {...register("taskTitle", {
                              required: true,
                              minLength: 3,
                            })}
                          />
                          {errors.taskTitle &&
                            errors.taskTitle.type === "required" && (
                              <span class="error-msg">
                                This field is required
                              </span>
                            )}
                          {errors.taskTitle &&
                            errors.taskTitle.type === "minLength" && (
                              <span class="error-msg">
                                This field required min 3 charcters
                              </span>
                            )}
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <label for="taskDesc" class="col-sm-4 col-form-label">
                          Task URL
                        </label>
                        <div class="col-sm-8">
                          <input
                            type="text"
                            class="form-control input-form"
                            id="taskDesc"
                            {...register("taskDesc", {
                              required: true,
                              minLength: 3,
                            })}
                          />
                          {errors.taskDesc &&
                            errors.taskDesc.type === "required" && (
                              <span class="error-msg">
                                This field is required
                              </span>
                            )}
                          {errors.taskDesc &&
                            errors.taskDesc.type === "minLength" && (
                              <span class="error-msg">
                                This field required min 3 charcters
                              </span>
                            )}
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <label for="taskReward" class="col-sm-4 col-form-label">
                          Task Reward
                        </label>
                        <div class="col-sm-8">
                          <input
                            type="text"
                            class="form-control input-form"
                            id="taskReward"
                            {...register("taskReward", {
                              required: true,
                              minLength: 2,
                              validate: (value) =>
                                /^[0-9]+$/.test(value) ||
                                "Only numeric values are allowed",
                            })}
                          />
                          {errors.taskReward &&
                            errors.taskReward.type === "required" && (
                              <span class="error-msg">
                                This field is required
                              </span>
                            )}
                          {errors.taskReward &&
                            errors.taskReward.type === "minLength" && (
                              <span class="error-msg">
                                This field required min 2 charcters
                              </span>
                            )}
                          {errors.taskReward &&
                            errors.taskReward.type === "validate" && (
                              <span className="error-msg">
                                {errors.taskReward.message}
                              </span>
                            )}
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <label class="col-sm-4 col-form-label">Task Type</label>
                        <div class="col-sm-8">
                          <Select
                            value={selectedOption}
                            onChange={handleChange}
                            options={options}
                            placeholder="Select a flavor"
                            isSearchable
                            className="input-form"
                          />
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <label
                          for="staticEmail"
                          class="col-sm-4 col-form-label"
                        >
                          Image{" "}
                        </label>
                        <div class="col-sm-8">
                          <div class="input-group">
                            <input
                              type="file"
                              class="form-control input-form"
                              id="inputGroupFile03"
                              name="taskImage"
                              aria-describedby="inputGroupFileAddon03"
                              aria-label="Upload"
                              {...register("taskImage", { required: true })}
                              accept=".png,.jpeg,.jpg"
                            />
                          </div>
                          {errors.taskImage && (
                            <span className="error-msg">
                              Please upload an image
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="text-center">
                        <button className="custom-btn"> Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Addtask;
